<template>
  <div>
    <van-tabs v-model="tabActive" title-active-color="#387ff5" title-inactive-color="#999" color="#387ff5" @change="tabChange">
      <van-tab :title="tab.title" :name="tab.name" v-for="tab in tabList" :key="tab.id" >
        <div class="info" style="margin-top: 0;" v-if="tab.name == '0'">
          <van-popup v-model="typeShow"   position="bottom"  >
            <van-picker title="请选择" show-toolbar :columns="typeList" value-key="label"  @confirm="typeConfirm"  @cancel="typeShow = false"/>
          </van-popup>
          <van-popup v-model="levelShow"   position="bottom"  >
            <van-picker title="请选择" show-toolbar :columns="levelList" value-key="label"  @confirm="levelConfirm"  @cancel="levelShow = false"/>
          </van-popup>
          <van-cell-group>
            <van-cell title="需求人" :value="dataForm.userName"/>
            <van-cell title="需求类型" is-link :value="dataForm.typeStr"  @click="typeShow = true" required/>
            <van-cell title="需求程度" is-link :value="dataForm.levelStr" @click="levelShow = true" required/>
            <van-cell title="需求内容" :border="false" required/>
            <van-field
                clearable
                clear-trigger="always"
                :border="false"
                class="left"
                v-model="dataForm.remark"
                rows="1"
                autosize
                :label-class="'textArea'"
                type="textarea"
                placeholder="请填写需求"
                input-align="left"
            />
          </van-cell-group>
          <van-row class="btns">
            <van-col :span="24" style="padding-top: 80px">
              <van-button type="info" size="large" round @click="submit">确认上报</van-button>
            </van-col>
          </van-row>
        </div>
        <div class="notice" v-if="tab.name == '1'">
          <div class="cont">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" offset="10">
              <van-cell v-for="(item, index) in dataList" :key="index">
                <van-cell-group>
                  <van-cell>
                    <van-row>
                      <van-col :span="6">{{item.typeStr}}</van-col>
                      <van-col :span="10" style="font-size: 12px;color: #999;">{{item.reportTime}}</van-col>
                      <van-col :span="8">
                        <van-tag round type="primary" :style="{'color': item.levelColor, 'backgroundColor': item.backColor}">{{item.levelStr}}</van-tag>
                      </van-col>
                    </van-row>
                    <div class="divider"></div>
                    <van-row class="remark">
                      <van-col :span="24">
                        {{item.remark}}
                      </van-col>
                    </van-row>
                  </van-cell>
                </van-cell-group>
              </van-cell>
            </van-list>
          </div>
          <van-empty description="没有数据哦" v-if="dataList.length < 1"/>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data () {
    return {
      tabActive: '0',
      totalCount: 0,
      tabList: [
        {id:1, title: '上报需求', name: '0'},
        {id:2, title: '需求记录', name: '1'}
      ],
      loading: false,
      finished: false,
      dataList: [],
      page: 0,
      limit: 10,
      typeShow: false,
      typeList: [{value: '1', label: '生活类型'}, {value: '2', label: '医疗类型'}],
      levelShow: false,
      levelList: [{value: '1', label: '一般'}, {value: '2', label: '紧急'}, {value: '3', label: '极紧急'}],
      dataForm: {
        userId: '',
        userName: '',
        typeStr: '请选择',
        type: '',
        levelStr: '请选择',
        level: '',
        remark: '',
      }
    }
  },
  computed: {...mapState(['houseId'])},
  methods: {
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/demand/list'),
        method: 'post',
        params: this.$http.adornParams({
          limit: this.limit,
          page: this.page,
          userId: this.dataForm.userId,
          isMedical: 0
        })
      }).then(({data})=> {
        if (data.code == 0) {
          data.page.list.map(item => {
            item['levelColor'] = ''
            item['backColor'] = ''
            if (item.level == 1) {
              item.levelColor = '#1A67FF'
              item.backColor = 'rgba(78, 124, 245, 0.12)'
            } else if(item.level == 2) {
              item.levelColor = '#FA5000'
              item.backColor = 'rgba(248, 186, 129, 0.2)'
            } else {
              item.levelColor = '#FF3636'
              item.backColor = 'rgba(246, 129, 112, 0.11)'
            }
          })
          this.totalCount = data.page.totalCount
          if (this.dataList.length === data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.loading = false;
          this.$toast.clear()
        }
      })
    },
    tabChange () {
      this.page = 0
      this.dataList = []
      this.finished = false
      if (this.tabActive == '1') {
        this.getDataList()
      }
    },
    typeConfirm (value) {
      if (value) {
        this.dataForm.typeStr = value.label
        this.dataForm.type = value.value
      }
      this.typeShow = false
    },
    levelConfirm (value) {
      if (value) {
        this.dataForm.levelStr = value.label
        this.dataForm.level = value.value
      }
      this.levelShow = false
    },
    submit () {
      if (this.dataForm.typeStr === '请选择') {return this.$toast.fail('失败:请先选择需求类型');}
      if (this.dataForm.levelStr === '请选择') {return this.$toast.fail('失败:请先选择需求程度');}
      if ( this.dataForm.remark === '') {return this.$toast.fail('失败:需求内容不能为空');}
      this.$http({
        url: this.$http.adornUrl('/wxapp/demand/save'),
        method: 'post',
        data: this.$http.adornData({
          'houseId': this.houseId,
          'level': this.dataForm.level,
          'remark': this.dataForm.remark,
          'reportUserId': this.$globalData.userInfo.userId,
          'type': this.dataForm.type,
          'userId': this.dataForm.userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('新增成功')
          this.$router.back()
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
  },
  mounted() {
    this.dataForm.userId = this.$route.query.userId
    this.dataForm.userName = this.$route.query.userName
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  .cont {
    padding: 0 28px 160px;
    .van-cell {
      padding: 10px 15px;
    }
  }
  .van-tag {
    color: #1A67FF;
    background-color: rgba(78, 124, 245, 0.12);
    padding: 8px 35px !important;
    float: right;
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
    margin: 25px 0;
  }
  .remark {
    color: #333;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
